<template>
	<div>
		<a-crm-container>
			<template>
				<div class='a-crm-content'>
					<div class='a-crm-content-box'>
						<p class='a-sub-heading a-crm-content-title'>{{$t("crm.exchange_rates_archive")}}</p>

						<div class='a-crm-archive-header'>
							<p class='a-crm-archive-header-title'>{{$t("crm.exchange_rate_per_unit_of_currency")}}</p>
							<div class='a-crm-archive-header-content'>
								<div class='a-crm-archive-header-dates'>
									<p class='a-crm-archive-header-title'>з</p>
									<date-picker
										v-model="dateFrom"
										:placeholder='$t("account.form.placeholder.choose_a_date")'
										:lang="$route.params.lang ? $route.params.lang : 'uk'"
										class='mx-datepicker-small'
										:disabled-date="date => date > new Date().setHours(0, 0, 0, 0)"
										:append-to-body='false'
										:editable='false'
										format="YYYY-MM-DD"
										valueType="format"
									/>
									<p class='a-crm-archive-header-title'>{{$t("account.by")}}</p>
									<date-picker
										v-model="dateTo"
										:placeholder='$t("account.form.placeholder.choose_a_date")'
										:lang="$route.params.lang ? $route.params.lang : 'uk'"
										class='mx-datepicker-small'
										:disabled-date="date => date > new Date().setHours(0, 0, 0, 0)"
										:append-to-body='false'
										:editable='false'
										format="YYYY-MM-DD"
										valueType="format"
									/>
								</div>
								<div class='a-crm-archive-header-buttons'>
									<v-button xxsmall
										color="green-persian white--text"
										@click="filterData"
									>{{$t("account.buttons.filter")}}</v-button>
								</div>
							</div>
						</div>

						<a-data-table v-if="data.length"
							:headers='headers'
							:items='data.slice((page - 1) * perPage, page * perPage)'
							class='a-crm-data-table-archive'
						/>

						<v-pagination
							v-if="data.length"
							:total='data.length'
							:page='page'
							:perPage='perPage'
							@update-pagination='page = $event'
							:hideCount='true'
							size='small'
						/>
					</div>
				</div>
			</template>
		</a-crm-container>
	</div>
</template>

<script>
	import ACrmContainer from '@/views/crm/container';
	import ADataTable from '@/views/crm/data-table';
	import { i18n } from '@/plugins/i18n'
	import {cabinet} from "@/services/request";

	export default {
		components: {
			ACrmContainer,
			ADataTable
		},
		data: () => ({
			dateFrom: null,
			dateTo: null,
			page: 1,
			perPage: 10,
			data:[],
			items: []
		}),
		computed: {
			headers() {
				return [
					{ text: 'USD', value: 'USD' },
					{ text: 'EUR', value: 'EUR' },
					{ text: i18n.t("crm.date"), value: 'format_date' },
				]
			},
			// showItems(){
			//
			// }
		},
		created() {
			this.getData();
		},
		methods:{
			getData(){
				cabinet.courses().then(r => {
					this.items = r.data;
					this.filterData()
				})
			},
			filterData(){
				this.data = [];
				this.page = 1;
				this.items.forEach(item => {
					if(this.dateFrom && item.date <= this.dateFrom) return
					if(this.dateTo && item.date >= this.dateTo) return

					this.data.push(Object.assign({
						format_date: this.$moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
					}, item))
				});
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-crm-archive-header {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		@include maxw( $grid-breakpoints-md - 1 ) {
			flex-wrap: wrap;
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			justify-content: center;
		}
		&-title {
			font-size: 12px;
			font-weight: 400;
			flex-shrink: 0;
			@include maxw( $grid-breakpoints-md - 1 ) {
				margin-bottom: 15px;
			}
		}
		&-content {
			display: flex;
			align-items: center;
			.a-crm-archive-header-title {
				margin-bottom: 0;
			}
			@include maxw( $grid-breakpoints-md - 1 ) {
				flex: 0 1 100%;
				justify-content: space-between;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
		}
		&-dates {
			display: flex;
			align-items: center;
			@include minw( $grid-breakpoints-xs ) {
				margin-right: 15px;
			}
			@include minw( $grid-breakpoints-md ) {
				margin: 0 15px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-bottom: 20px;
			}
			.mx-datepicker {
				margin: 0 5px;
				max-width: 150px;
				@include minw( $grid-breakpoints-xs ) {
					margin: 0 10px;
				}
				.mx-input {
					min-height: 35px;
					font-size: 14px;
				}
			}
		}
		&-buttons {
			.app-btn {
				height: 35px;
				font-size: 15px;
			}
		}
	}

	.a-crm-data-table-archive {
		box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
		border-radius: 5px;
		margin-bottom: 10px;
		.a-crm-data-table {
			table-layout: fixed;
			th {
				color: $white;
				background-color: $green-persian;
			}
		}
	}
</style>
